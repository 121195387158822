import { SecurityContext } from '@lib';
import { navigate } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';

type SignupPageProps = {};

const SignupPage: FC<SignupPageProps> = () => {
  const { signupControl } = useContext(SecurityContext);

  useEffect(() => {
    if (signupControl) {
      navigate('/');
      signupControl.open();
    }
  }, [signupControl]);

  return null;
};

export default SignupPage;
